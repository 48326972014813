html, body, #root, .App, .wrapper{
  height: 100%;
}

.grecaptcha-badge { visibility: hidden; }

.grecaptcha-info{
  color: #b6b6b6;
    text-align: left;
    font-size: 12px;
}

@-moz-keyframes spin {
  from { -moz-transform: rotate(-45deg); }
  to { -moz-transform: rotate(-405deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(-45deg); }
  to { -webkit-transform: rotate(-405deg); }
}
@keyframes spin {
  from {transform:rotate(-45deg);}
  to {transform:rotate(-405deg);}
}

.underlined{
  text-decoration: underline;
}

.gauge-container{
  margin: 0 15px;
}
.gauge-container .min,
.gauge-container .max{
  display: inline-block;
}
.gauge-container .min{
  float: left;
}
.gauge-container .max{
  float: right;
}
.gauge{
  margin-top: 15px;
  width: 400px;
  height: 200px;
  overflow: hidden;
  text-align: center;
  transform: translate3d(0, 0, 0);
}
.gauge-a{
  z-index: 1;
  position: absolute;
  background-color: rgba(0,0,0,.1);
  width: 400px;
  height: 200px;
  top: 0%;
  border-radius: 250px 250px 0px 0px;
}
.gauge-b{
  z-index: 3;
  position: absolute;
  background-color: white;
  width: 250px;
  height: 125px;
  top: 75px;
  margin-left: 75px;
  margin-right: auto;
  border-radius: 250px 250px 0px 0px;
}
.gauge-b1{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 1px;
  left: 0;
  z-index: -1;
  margin: 2px 2px 0 2px;
  border-radius: inherit;
  background: linear-gradient(to right, red 0%, red 33.3%, orange 33.3%, orange 66.6%, green 66.6%, green);
}
.gauge-b2{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: 6px 6px 0 6px;
  border-radius: inherit;
  background: white;
}
.gauge-c{
  z-index: 2;
  position: absolute;
  width: 400px;
  height: 200px;
  top: 200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0px 0px 200px 200px;
  transform-origin: center top;
  transition: all 1.3s ease-in-out;
}
.gauge-data{
  z-index: 4;
  font-size: 1.5em;
  position: absolute;
  width: 400px;
  height: 200px;
  top: 130px;
  margin-left: auto;
  margin-right: auto;
  transition: all 1s ease-out;
}



#lastBuild {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: 0;
  padding: 15px 0px 15px 30px;
}

.lessPadding > div > div > div,
.lessPadding > div > input {
  padding: 12.5px 30px 12.5px 14px;
}
.call-note-width {
  width: 90%;
}

.fullWidth{
  width: 100% !important;
}

.notes-file-upload{
  margin-top : 20px;
}
.notes-file-upload input{
  display : none;
}
.notes-file-upload ul{
  margin-bottom: 0;
  list-style-type: none;
  padding: 0;
}
.notes-file-upload ul li{
  border: 1px solid #e9eaec;
  border-radius: 4px;
  background-color: #e9eaec;
  padding: 10px;
  margin: 5px 0 0;
  position: relative;
}

.editTime-background{
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 200;
}
.editTime-wrapper{
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background: #fff;  
  left: 0px; 
  padding: 12px 10px;
  box-sizing: border-box;
  z-index: 201;
  width: 400px;
}
.editTime-timeContainer{
  position: relative;
  width: 47%;
  padding: 0 5px;
  display: inline-block;
  vertical-align: top;
  line-height: 16px;
  text-align: left;
}
.editTime-timeContainer input{
  padding: 10px 12px;
  box-sizing: border-box;
}

.homepage .card .time .line{
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  position: relative;
}
.homepage .card .time .line:last-child{
  border-bottom: none; 
}
.homepage .card .time .total{
  position: absolute;
  top: 26px;
  right: 35px;
}
.homepage .card .time .total p{
  font-size: 16px;
}
.homepage .card .time .line{
  padding: 10px;
}
.homepage .card .time .line .time,
.homepage .card .time .line .options{
  display: inline-block;
  vertical-align: middle;
}
.homepage .card .time .line .time{
  width: 100%;
  text-align: left;
}
.homepage .card .time .line .time p{
  display: inline-block;
  width: 50%;
}
.homepage .card .time .line .time p#total{
  text-align: right;
}
.homepage .card .time .line .time p#hours{
  text-align: left;
}

.side-menu .offline{
  padding: 10px 15px;
  border-radius: 4px;
  background-color: crimson;
  margin: 15px auto 0;
  width: 85%;
  box-sizing: border-box;
}
.side-menu .offline p{
  color: white;
}

.avatar{
  pointer-events: none;
}

.sub-menu{
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  justify-content: space-between;
  box-sizing: border-box;
}
.sub-menu .action-buttons{
  margin-left: auto;
}
.sub-menu .action-buttons button{
  margin-left: 10px;
}

.modal{
  position: absolute !important;
}
.modal-buttons-wrapper{
  padding: 0 20px 20px;
  margin: 8px 0 0 !important;
}

.tui-full-calendar-dayname-leftmargin,
.tui-full-calendar-timegrid-right{
  margin-left: 50px !important;
}
.tui-full-calendar-timegrid-left{
  width: 50px !important;
}
.tui-full-calendar-section-button .tui-full-calendar-content{
  top: 0 !important;
}
.tui-full-calendar-allday-left{
  width: auto !important;
}
.tui-full-calendar-daygrid-layout{
  position: relative;
  right: 4px;
  width: 100.4%;
}

.table-wrapper{
  display: flex; 
  flex-wrap: nowrap;
  overflow-x: auto; 
  -webkit-overflow-scrolling: touch;
}
.table-wrapper table{
  flex: 0 0 auto;
}
.homepage .card-recentdonations .table-wrapper{
  overflow: hidden;
}

.return-arrow{
  position: absolute !important;
  z-index: 3;
  top: 8px;
  left: 15px;
  background-color: white !important;
  padding: 15px !important;
}

.mandatoryFields-hint{
  font-size: 12px;
  text-align: left;
  margin-bottom: 20px;
}

.quick-actions-bar{
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  border-top: 1px solid rgb(221, 219, 218);
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center; 
}
.quick-actions-bar .action-item{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.quick-actions-bar .action-item .icon{
  padding: 5px;
}
.quick-actions-bar .action-item .text{
  font-size: 12px;
}

.input-wrapper{
  text-align: left;
}
.input-wrapper > div,
.input-wrapper.multiple > div > div{
  box-sizing: border-box;
}
.input-wrapper.filled > div,
.input-wrapper.multiple > div.filled > div{
  background-color: white;
}
.input-wrapper.multiple{
  display: flex;
  justify-content: space-between;
}

.input-wrapper label{
  transition: all 250ms ease;
}
.input-wrapper.multiple .filled label,
.input-wrapper.filled label{
  background-color: #c9c9c9;
  border-radius: 3px;
  padding: 2px 8px;
  color: white;
}

.input-with-country{
  flex-direction: row !important;
}
.input-with-country .countryCode{
  width: 23%;
}
.input-with-country .countryCode fieldset{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-with-country .value{
  width: calc(77% + 1px);
  margin-left: -1px;
}
.input-with-country .value fieldset{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}


.buttons-wrapper{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.buttons-wrapper button,
.buttons-wrapper a{
  width: 45%;
  height: 57px;
  font-size: 16px;
}
.buttons-wrapper a button{
  width: 100%;
}
.buttons-wrapper button:first-child:nth-last-child(1),
.buttons-wrapper a:first-child:nth-last-child(1){
  margin: auto;
}


.input-adornment p{
  font-size: 26px;
}

a{
  text-decoration: initial;
  cursor: pointer;
  color: inherit;
}

a.underline{
  text-decoration: underline;
}

.success{
  color: #00C851;
}

.error-message{
  padding: 15px;
  background-color: #f44336c2;
  color: white;
  border-radius: 2px;
  margin: 25px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  line-height: 25px;
}
.error-message svg,
.error-message span{
  margin: 0 5px;
}

.date-selector{
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  flex-wrap: wrap;
}
.date-selector label,
.input-with-country label{
  background: white;
  padding: 0 5px;
  margin-left: -5px;
  transition: all 250ms ease;
}
.form .date-selector label,
.form .input-with-country label{
  background: #f5f6f5;
}
.date-selector label.filled,
.input-with-country label.filled{
  background-color: #c9c9c9;
  border-radius: 3px;
  padding: 2px 8px;
  color: white;
}

.date-selector .input-wrapper{
  width: auto;
  flex-grow: 1;
}
.date-selector .input-wrapper.day fieldset{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.date-selector .input-wrapper.month fieldset{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.date-selector .input-wrapper.year fieldset{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.date-selector .input-wrapper.month,
.date-selector .input-wrapper.year{
  margin-left: -1px;
}
.date-selector .error{
  width: 100%;
  margin: 8px 12px 0;
  flex-basis: 100%;
}

.loader-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0,0,0,0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
}
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: -2.5em auto 2.5em;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  z-index: 3;
  top: 50%;
}
.loader-wrapper .text{
  display: block;
  position: relative;
  top: 53%;
  color: white;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -2.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 2.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.checkbox{
  margin-top: 10px;
  align-items: flex-start !important;
}
.checkbox span{
  padding-top: 0;
  padding-bottom: 0;
}


.App {
  text-align: center;
  background-color: #eeeeee;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.bonus-conditions .date-selector .filled{
  background-color: white;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.54);
}

.homepage,
.calendar,
.reports,
.reports-folders,
.form,
.donation-page,
.setup-page,
.map,
.user-setup,
.team-assignment,
.notes,
.times,
.goals,
.bonus-list{
  padding-top: 70px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  z-index: 1;  
  -webkit-overflow-scrolling: touch;
}

.bonus-list{
  padding: calc(70px + 3%) 3%;
}

.input-error{
  font-size: 12px;
  color: #F6EB13;
  text-align: left;
  width: 100%;
  margin-top: 5px;
}

.login-background{
  height: 100%;
  width: 100%;
  background: url("../Resources/app_background_optimized2.png")  #013259;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 10%;
}

.login-background .logo{
  color: white;
  padding: 54px 0;
  margin-bottom: 25px;
  background: url("../Resources/logo_azul.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.login-wrapper{
  max-width: 350px;
  background-color: white;
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  margin: auto;
  padding: 40px;
  position: relative;
  border-radius: 2px;
  transition: height 0.3s;
  top: 50%;
  transform: translateY(-50%);
}

.login-wrapper .submit-success{
  color: #000;
  margin-top: 30px;
}
.login-wrapper .submit-success .icon{
  font-size: 70px;
}
.login-wrapper .title{
  font-weight: lighter;
  font-size: 26px;
  text-align: center;
}
.login-wrapper .text{
  font-weight: lighter;
  font-size: 15px;
}

.login-wrapper h5{
  margin: 20px 0 10px;
  text-align: left;
  color: #00214e;
}


.login-wrapper .info-text{
  color: black;
  font-size: 12px;
  margin: 30px 0 20px;
  color: #00214e;
}

.login-wrapper img{
  max-height: 150px;
  max-width: 100%;
}

.login-wrapper .input-wrapper{
  margin: 0 0 20px;
}
.login-wrapper .input-wrapper > div{
  border: none;
}

.login-wrapper .error-message{
  margin-bottom: -15px;
}

.login-wrapper .buttons-wrapper{
  margin-top: 40px;
}

.login-wrapper .buttons-wrapper button,
.login-wrapper .buttons-wrapper .loader-wrapper{
  width: 100%;
}

.login-wrapper .forgot-password,
.login-wrapper .cancel{
  font-size: 12px;
  cursor: pointer;
  margin-top: 10px;
  color: #00214e;
}

.login-wrapper button[type="submit"]{
  height: 45px;
  background: #00214e;
}

.login-form, .reset-password{
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0 40px;
  box-sizing: border-box;
  transition: opacity 0.3s, visibility 0.3s;
}

.reset-password{
  visibility: hidden;
  opacity: 0;
}

.visible{
  visibility: visible;
  opacity: 1;
}
.not-visible{
  visibility: hidden;
  opacity: 0;
}

.password-form button{
  width: 100%;
  height: 40px;
}

.modal.editObjective button{
  min-height: 50px;
}

.modal-wrapper{
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  z-index: 998;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,0.1);
}

.menu-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.menu-item .text{
  font-weight: 300;
  font-size: 14px;
}

.menu-item.search.active{
  width: calc(100% - 125px) !important;
}
.menu-item.search{
  right: 70px;
  transition: all 0.25s ease;
  position: absolute;
  background: white;
  justify-content: flex-start;
  flex-direction: row;
}

.top-menu{
  width: 100%;
  height: 70px;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
  background-color: #fff;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 3;
  padding: 0 15px;
  box-sizing: border-box;
}

.top-menu .ong-name{
  font-weight: normal;
  margin-left: 5px;
}
@media screen and (max-width: 600px) {
  .top-menu .ong-name{
    display: none;
  }
}

.top-menu .icon{
  cursor: pointer;
  font-size: 30px;
}
.top-menu .sync .icon{
  transition: transform 0.3s;
}
.top-menu .sync:hover .icon{
  transform: rotate(-45deg);
}
.top-menu .sync .icon.synching{
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

.top-menu .menu-item-wrapper{
  width: auto;
  height: 100%;
  margin-left: auto;
  display: flex;
}
.top-menu .menu-item-wrapper .menu-item{
  height: 100%;
  width: 55px;
}
.top-menu .menu-item-wrapper .menu-item button{
  color: #B3B4B4;
}
.top-menu .menu-item-wrapper .menu-item button:hover{
  color: rgba(0, 0, 0, 0.54);
}

.menu-item.timer button{
  color: white !important;
  background-color: #5acd27;
  width: 40px;
  height: 40px;
}
.menu-item.timer svg{
  position: relative;
  bottom: 7px;
}
.menu-item.timer.active button{
  background-color: #e40f1c;
}

.search-bar{
  background-color: #E4E4E4;
  color: #525352;
  height: 50%;
  width: 200px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  margin-left: 5%;
}
.search-bar svg{
  margin: 0 15px;
  position: absolute;
}
.search-bar input{
  font-size: 14px;
  background: none;
  border: none;
  padding: 0;
  height: 100%;
  padding-left: 40px;
  width: 100%;
  box-sizing: border-box;
}

.side-menu .search-bar{
  height: 40px;
  width: 85%;
  margin: 15px 0;
  background-color: #4e4e4e;
  color: #b3b4b4;
  border-radius: 2px; 
}
.side-menu .search-bar input{
  color: #e4e4e4;
  padding-left: 55px;
}
.side-menu .search-bar input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b3b4b4;
  opacity: 1; /* Firefox */
}
.side-menu .search-bar input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #b3b4b4;
}
.side-menu .search-bar input::-ms-input-placeholder { /* Microsoft Edge */
  color: #b3b4b4;
}

.side-menu{
  width: 250px;
  margin-left: 0;
  transition: margin 0.3s;
  background-color: #252525;
  height: 100%;
}
.side-menu .menu-item{
  color: rgb(206, 206, 206);
  padding: 15px 0px 15px 30px;
  flex-direction: row;
  justify-content: left;
}
.side-menu .menu-item:hover{
  color: #f5f5f5;
  fill: #f5f5f5;
}
.side-menu .menu-item.disabled{
  opacity: 0.4;
}
.side-menu .menu-item.disabled:hover{
  color: rgb(206, 206, 206);
}
.side-menu .menu-item.active{
  background-color: #E4E4E4;
  color: #252525;
}
.side-menu .menu-item .icon{
  margin-right: 15px;
}

.side-menu .profile-menu{
  cursor: pointer;
  position: relative;
  padding: 20px 0 20px 27px;
  border-bottom: 1px solid;
}
.side-menu .profile-menu .avatar{
  width: 50px;
  height: 50px;
}
.side-menu .profile-menu .avatar .default-image{
  font-size: 56px;
  color: white;
}
.side-menu .profile-menu .text{
  margin-left: 13px;
}
.side-menu .profile-menu .text .name{
  color: white;
}
.side-menu .profile-menu .text .profile{
  color: white;
  opacity: 0.6;
}
.side-menu .profile-menu .arrow-down{
  position: absolute;
  right: 0;
}



.homepage{
  padding: calc(70px + 5%) 5% calc(50px + 5%);
  box-sizing: border-box;
  position: inherit;
}

.homepage .new-form-card{
  margin-bottom: 40px;
  width: 100%;
  height: 55px;
  border-radius: 27px;
  text-transform: none;
  font-size: 16px;
}

.homepage .card.quote #quote{
  text-align: center;
}
.homepage .card.quote .transparentInput> div{
  padding: 10px 12px 10px;
  background: none;
}
.homepage .card.quote .transparentInput> div::before{
  border: none !important;
}
.homepage .card.quote .transparentInput .focused{
  background-color: rgba(0, 0, 0, 0.09);
}

.homepage .card{
  margin-bottom: 40px;
  width: 100%;
  border-radius: 4px;
  padding: 25px;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: white;
  position: relative;
}
.homepage .card .title{
  text-align: left;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
  width: 100%;
}
.homepage .card table tr{
  cursor: pointer;
}

.homepage .card table button{
  font-size: 12px;
}

.card-recentdonations table tr th:last-child,
.card-recentdonations table tr td:last-child{
  text-align: right;
  padding-right: 0; 
}
.card-recentdonations table tr th:first-child,
.card-recentdonations table tr td:first-child{
  text-align: left;
  padding-left: 0; 
}

.card-recentdonations .title,
.card-recentdonations .filter{
  display: inline-block;
  width: 50% !important;
}
.card-recentdonations .filter{
  text-align: right;
}

/*.card-statistics{
  background-color: white;
  margin-bottom: 0 !important;
}
.card-statistics button{
  position: absolute;
  top: -20px;
  right: 0;
  border: 0;
  padding: 0;
  font-weight: 600;
  font-size: 12px;
  background-color: inherit;
}

.percentage-by-country-chart{
  font-size: 14px;
  color: #525352;
  margin-bottom: 10px;
}
.percentage-by-country-chart .text{
  margin: 0;
}
.percentage-by-country-chart .bar{
  display: inline-block;
  vertical-align: middle;
  height: 4px;
  border-radius: 2px;
  margin-right: 10px;
}*/

.donation-page{
  background-color: white;
}
.donation-page hr{
  border-top: 1px solid #E4E4E4;
  margin: 0;
}

.donation-page .details-wrapper{
  padding: 5%;
}
.donation-page .details-wrapper.header{
  padding: 3% 5%;
}
.donation-page .details-wrapper.header .title{
  text-align: left;
  margin: 16px 0;
  font-weight: bold;
  color: inherit;
}
.donation-page .details-wrapper.footer{
  background-color: #eeeeee;
}
.donation-page .info{
  padding: 5px 20px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.donation-page .info p,
.donation-page .info h4{
  margin: 5px 0;
}

.donation-page .info h4{
  opacity: 0.7;
  font-weight: 400;
}

.donation-page .info .info-field{
  margin-bottom: 20px;
  width: 50%;
}
.donation-page .info .info-field.full-width{
  width: 100%;
}

.donation-page .edit:nth-child(1){
  margin-bottom: 40px;
}
.donation-page .edit button,
.donation-page .edit a{
  margin: 8px;
}

.donation-page .manual-location{
  width: 100%;
  margin-bottom: 30px;
}

.donation-page .locationMap{
  width: 100%;
  height: 400px;
}

.status-color{
  display: inline-block;
  vertical-align: bottom;
  border-radius: 50%;
  margin-left: 5px;
  width: 18px;
  height: 18px;
  background: white;
}
.status-color span{
  width: 12px;
  height: 12px;
  display: block;
  border-radius: 50%;
  position: relative;
  top: 3px;
  left: 3px;
}

/*.map .infoBox{
  background-color: white;
  box-shadow: 0 0 30px rgba(0,0,0,0.2);
  padding: 20px;
  width: 250px !important;
  border-radius: 2px;
  overflow: unset !important;
}

.map .infoBox img{
  width: 20px;
  height: auto;
  bottom: 5px;
}

.map .infobox-content button{
  background-color: black;
  color: white;
  padding: 10px 30px;
  border-radius: 20px;
  border: none;
  font-size: 14px;
  cursor: pointer;
}    

.map .infobox-content .header {
  display: flex;
  margin: 0 -20px 0;
}

.map .infobox-content .person-info {
  margin-left: -10px;
}

.map .infobox-content .person-info .name,
.map .infobox-content .person-info .team {
  margin: 0;
  font-weight: 400;
  text-align: left;
}

.map .infobox-content .person-info .team {
  font-size: 14px;
  color: #525352;
  margin: 5px 0;
}

.map .infobox-content .person-stats{
  margin: 0px -20px 25px;
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid #E4E4E4;
  padding: 15px 20px; 
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
}
.map .infobox-content .person-stats .stat{
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
}

.map .infobox-content .image-wrapper{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: white;
  position: relative;
  display: inline-block;
  left: -25px;
  bottom: 30px;
  padding: 5px;
}
.map .infobox-content .image-wrapper .image{
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background: black;
}

.map .teams-cards{
  position: absolute;
  bottom: 15px;
  left: 15px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.map .teams-cards .card{
  background-color: #F6EB13;
  border-radius: 4px;
  padding: 25px 20px;
  text-align: left;
  width: 150px;
  margin: 20px 12px;
  box-shadow: 0 0 10px 3px rgba(0,0,0,0.1);
  opacity: 0.8;
  cursor: pointer;
}
.map .teams-cards .card.active{
  opacity: 1;
  transform: scale(1.1);
}

.map .teams-cards .card .team-info{
  margin: 40px 0;
}

.map .teams-cards .card .team-info .name,
.map .teams-cards .card .team-info .city{
  font-weight: 400;
  margin: 0;
}

.map .teams-cards .card .team-info .name{
  font-size: 28px;
}
.map .teams-cards .card .team-info .city{
  font-size: 16px;
  text-transform: uppercase;
}

.map .teams-cards .card .team-stats{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
.map .teams-cards .card .team-stats .stat{
  display: block;
  font-size: 16px;
  margin-top: 5px;
}

.map .teams-cards .card .team-abrv{
  background-color: black;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
}

.map .team-bar{
  position: absolute;
  background-color: white;
  top: 70px;
  padding: 25px 60px;
  text-align: left;
  width: calc(100% - 120px);
  border-bottom: 2px solid #E4E4E4;
}
.map .team-bar .team-name{
  margin: 0;
  font-size: 20px;
  font-weight: 400;
}

.map .team-bar .members-wrapper{
  display: flex;
  margin-top: 20px;
}

.map .team-bar .members-wrapper .member{
  margin: 0 15px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.map .team-bar .members-wrapper .member .image{
  background: black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 10px 3px rgba(0,0,0,0.2);
}
.map .team-bar .members-wrapper .member .name{
  margin-top: 5px;
  font-size: 14px;
}

.map .map-back-button{
  color: #B3B4B4;
  background-color: #231F20;
  font-size: 14px;
  position: absolute;
  padding: 6px 26px;
  top: 270px;
  right: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.map .map-back-button .icon{
  font-size: 28px;
  vertical-align: middle;
  margin-right: 10px;
}
.map .map-back-button .text{
  position: relative;
  top: 1px; 
}*/

.report-edit-wrapper,
.calendar-filters{
  width: 331px;
  transition: margin 250ms ease 0s;
  background-color: #f9f9fa;
  margin-left:  calc(-331px + 38px);
}
.report-edit-wrapper{
  margin-left:  -331px;
}
.report-edit-wrapper.open,
.calendar-filters.open{
  margin-left:  0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

.report-edit-wrapper .editor{
  width: 100%;
}

.report-edit-wrapper, 
.report-container,
.times .editor,
.calendar .calendar-filters,
.calendar .calendar-wrapper{
  display: inline-block;
  vertical-align: top;
  height: 100%;
}
.times .editor,
.calendar .calendar-filters .editor{
  padding: 25px;
  box-sizing: border-box;
}
.calendar .calendar-filters{
  text-align: left;
}
.calendar .calendar-wrapper{
  padding: 30px 50px 50px;
  box-sizing: border-box;
  background-color: white;
  flex-grow: 1;
}
.calendar-filters .title{
  margin-bottom: 25px;
  display: inline-block;
}
.calendar-filters .go-to-calendar-button{
  float: right;
}
.times .teams-list,
.calendar-filters .teams-list{
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}
.times .teams-list .team-wrapper,
.calendar-filters .teams-list .team-wrapper{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.times .teams-list .member-list,
.calendar-filters .teams-list .member-list{
  list-style-type: none;
  padding-inline-start: 33px;
  width: 100%;
}
.times .teams-list .member-list .member-list-item,
.calendar-filters .teams-list .member-list .member-list-item{
  margin: 5px 0;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}
.times .teams-list .member-list .member-list-item .avatar,
.calendar-filters .teams-list .member-list .member-list-item .avatar{
  width: 30px;
  height: 30px;
  margin-right: 10px; 
}
.times .teams-list .member-list .member-list-item .avatar .default-image,
.calendar-filters .teams-list .member-list .member-list-item .avatar .default-image{
  font-size: 32px;
}

.calendar,
.times{
  display: flex;
}
.calendar .calendar-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.calendar .calendar-header .calendar-views{
  cursor: pointer;
  font-size: 14px;
}
.calendar .calendar-header .calendar-views span:first-child{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.calendar .calendar-header .calendar-views span:last-child{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.calendar .calendar-header .calendar-views span{
  padding: 5px 10px;
  background: #f0f0f0;
  margin: 0 1px;
  display: inline-block;
  transition: all 100ms;
}

.calendar .calendar-header .calendar-month,
.calendar .calendar-header .calendar-views{
  margin-top: 20px;
}

.calendar .calendar-header .calendar-month{
  font-size: 20px;
}
.calendar .calendar-header .calendar-month .month{
  margin-left: 10px;
}
.calendar .calendar-header .calendar-month .year{
  margin-left: 10px;
  opacity: 0.4;
}
.calendar .calendar-header .calendar-month .arrow{
  background: #f0f0f0;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  top: 5px;
  border-radius: 4px;
}

.calendar #calendar{
  margin-top: 30px;
  position: relative;
}

.calendar #calendar .times-button{
  z-index: 1;
  position: absolute;
  right: 80px;
  bottom: 0;
}

.calendar #calendar .add-button{
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
}

.calendar .calendar-list{
  border-top: 2px solid #E4E4E4;
  padding: 30px 50px;
  text-align: left;
}

.calendar .tui-full-calendar-popup{
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 999;
  text-align: left;
}

.team-assignment::after{
  content: "";
  position: absolute;
  top: 0; 
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.1);
  z-index: -1;
}
.team-assignment{
  background: url("../Resources/app_background_optimized2.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  user-select: none;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.team-assignment .noTeam{
  padding: 10px;
  overflow: auto;
  display: flex;
  align-items: center;
  min-height: 45px;
  box-sizing: border-box;
  background: rgba(0,0,0, .5);
  -webkit-overflow-scrolling: touch;
}

.team-assignment .all-teams{
  margin-top: 10px;
  height: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.team-assignment .all-teams .team-card{
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  transition: background-color 0.1s ease 0s, opacity 0.1s ease 0s;
  background-color: #dfe3e6;
  border-radius: 4px;
  width: 250px;
  min-height: 100px;
  max-height: 97%;
  margin: 0 10px;
  text-align: left;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}
.team-assignment .all-teams .team-card .title{
  padding: 5px 5px 10px;
}

.team-assignment .all-teams .team-card .member-list-wrapper{
  overflow-y: scroll;
  min-height: 50px;
  -webkit-overflow-scrolling: touch;
}

.team-assignment .draggable .text{
  font-weight: normal;
}

.team-assignment .noTeam .draggable,
.team-assignment .all-teams .draggable{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.team-assignment .noTeam .draggable {
  margin: 0 10px;
}
.team-assignment .noTeam .draggable .text{
  color: white;
  line-height: 15px;
}

.team-assignment .noTeam .draggable.active{
  width: 230px !important;
  height: auto !important;
  padding: 8px 8px 6px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 8px;
  border: 1px solid #bbbbbb;
  box-shadow: 0 3px 4px rgba(116, 116, 116, 0.3);
}
.team-assignment .noTeam .draggable.active .text{
  color: inherit;
}

.team-assignment .all-teams .draggable{
  padding: 8px 8px 6px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0 1px 0 rgba(9,45,66,.25);
}

.team-assignment .all-teams .draggable.active{
  border: 1px solid #bbbbbb;
  box-shadow: 0 3px 4px rgba(116, 116, 116, 0.3);
} 

.team-assignment .all-teams .draggable:hover{
  background-color: #f5f6f7;
  border-bottom-color: rgba(9,45,66,.25);
}

.team-assignment .card-color{
  position: absolute;
  border-radius: 2px 2px 0 0;
  top: 0;
  left: 0;
  width: 100%;
}
.team-assignment .card-header .team-name{
  min-height: 49px;
  width: 85%;
}
.team-assignment .card-header .team-name .no-label{
  padding: 10px 12px 10px; 
}
.team-assignment .card-header .team-name .focused{
  background-color: rgba(0, 0, 0, 0.09);
}
.team-assignment .card-header .team-name > div{
  background: none;
}
.team-assignment .card-header .team-name > div:before{
  border: none !important;
}

.team-assignment .card-header .more-icon{
  min-width: unset;
  padding: 0 7px;
}
.team-assignment .card-header .more-icon svg{
  color: #798d99;
  font-size: 20px;
}
.team-assignment .card-header .more-icon:hover svg{
  color: #516b7a;
}

.team-assignment .all-teams .add-team{
  vertical-align: top;
  background-color: rgba(0,0,0,.5);
  width: 250px;
  margin: 0 10px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  transition: background-color 85ms ease-in, opacity 40ms ease-in, border-color 85ms ease-in;
  text-align: left;
  cursor: pointer;
  position: relative;
}
.team-assignment .all-teams .add-team:hover{
  background-color: rgba(0,0,0,.32);
}

.team-assignment .all-teams .add-team .placeholder{
  display: flex;
  align-items: center;
}
.team-assignment .all-teams .add-team .placeholder .icon,
.team-assignment .all-teams .add-team .placeholder .text{
  color: white;
}
.team-assignment .all-teams .add-team .placeholder .icon{
  font-size: 22px;
  margin-right: 5px;
}
.team-assignment .all-teams .add-team .add-team-form{
  display: none;
}

.color-picker-thumbnail{
  padding: 5px;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
  cursor: pointer;
}
.color-picker-thumbnail .current-color{
  width: auto;
  height: 45px;
  border-radius: 2px;
}
.color-picker-wrapper{
  position: absolute;
  z-index: 2;
}
.color-picker-closer{
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.team-assignment .all-teams .add-team .add-team-form button{
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
}
.team-assignment .all-teams .add-team .add-team-form .close{
  color: #798d99;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 10px;
  font-size: 20px;
}
.team-assignment .all-teams .add-team .add-team-form .close:hover{
  color: #516b7a;
}
.team-assignment .all-teams .add-team.mod-add{
  background-color: #dfe3e6;
  cursor: default;
  white-space: normal;
}
.team-assignment .all-teams .add-team.mod-add .add-team-form{
  display: block;
}

.team-assignment .draggable .avatar{
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}
.team-assignment .draggable .avatar .default-image{
  font-size: 32px;
}

.reports-folders{
  background-color: #f9f9fa;
}
.reports-folders .content{
  display: flex;
  height: calc(100% - 73px);
}
.reports-folders .content .folder-list{
  border-right: 1px solid #E4E4E4;
  flex: 0 0 200px;
}
.reports-folders .content .folder-list .icon{
  margin-right: 0;
}
.reports-folders .content .folder-list .arrow{
  font-size: 20px;
}
.reports-folders .content .folder-list .folder-list-item{
  padding-right: 15px;
}
.reports-folders .content .file-list{
  flex-grow: 1;
  background-color: white;
}


.reports{
  background-color: white;
  overflow: hidden;
}

.reports .header{
  background-color: #f9f9fa; 
}

.reports .header,
.reports .sub-header{
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.reports .header .buttons button{
  margin-left: 10px;
}

.reports .sub-header .totals{
  text-align: left;
}
.reports .sub-header .totals:first-of-type{
  margin-right: 5%;
}

.reports .sub-header .totals .description,
.reports .sub-header .totals .value{
  margin: 0;
}
.reports .sub-header .totals .description{
  font-size: 14px;
  font-weight: lighter;
}

.reports .sub-header .totals .value{
  font-size: 16px;
  font-weight: bold;
}

.reports .body{
  height: 100%;
}

.reports .chart{
  width: calc(100% - 60px);
  height: 350px;
  margin: 20px auto 80px;
  display: inline-block;
  position: relative;
}
.reports .chart canvas{
  margin-top: 40px;
}
.reports .chart .total{
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -62%);
  font-size: 34px;
  margin-left: 5px;
}
.reports .chart .properties-button{
  position: absolute;
  right: 0;
  border: 1px solid rgb(221, 219, 218);
  border-radius: 4px !important;
  padding: 6px;
}
.reports .chart .properties-button .icon{
  font-size: 20px;
}

.reports .report-header-options{
  border: 1px solid rgba(0, 0, 0, 0.54);
  width: 20px;
  height: 20px;
}
.reports .report-header-options:hover{
  border-color: #7d98b3;
  color: #7d98b3;
  cursor: pointer;
}

#chart-properties{
  overflow: scroll;
}
.chart-properties{
  padding: 15px;
  width: 300px;
  box-sizing: border-box;
}
.chart-properties hr{
  margin: 10px 0;
}
.chart-properties .chartType-option{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  width: 77px;
  height: 86px;
}
.chart-properties .chartType-option:nth-child(3n+2){
  margin: 4px;
}
.chart-properties .chartType-option:hover{
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}
.chart-properties .chartType-option .icon{
  font-size: 35px;
}
.chart-properties .chartType-option .text{
  margin-top: 5px;
}
.chart-properties .chartType-option input{
  display: none;
}

.reports table, 
.reports th, 
.reports td{
  border: 1px solid rgb(221, 219, 218);
}
.reports tr{
  height: auto;
}
.reports th,
.reports td{
  padding: 6px;
}

.reports table th{
  background-color: #e6ecf2;
}
.reports table .group{
  background-color: #f7fafc;
}
.reports table .subtotal{
  background-color: #f7fafc;
}
.reports table .total{
  background-color: #edf5fb;
}

.report-container,
.times .times-wrapper,
.calendar-wrapper{
  width: calc(100% - 300px);
  transition: width 250ms ease 0s;
}
.report-container{
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.report-edit-wrapper .tabs{
  border-bottom: 1px solid #e7e8e7;
}
.report-edit-wrapper .tabs .tab{
  min-width: calc(331px / 2);
}
.report-edit-wrapper .container{
  padding: 5%;
  text-align: left;
}

.editor,
.editor-opener{
  display: inline-block;
  vertical-align: top;
  height: 100%;
}
.editor{
  width: calc(100% - 38px);
}
.editor-opener{
  width: 36px;
  border-left: 1px solid #e7e8e7;
  border-right: 1px solid #e7e8e7;
}
.editor-opener .vertical-text,
.editor-opener .vertical-text .icon{
  color: rgb(112, 110, 107);
}
.editor-opener .vertical-text{
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: 18px;
  margin-top: 18px;
}
.editor-opener .vertical-text .icon{
  transition: transform 250ms ease 0s;
  margin-left: 10px;
}
.editor-opener .vertical-text .icon.reverse{
  transform: rotate(180deg) !important;
}
.editor .group-list,
.editor .filter-list{
  list-style: none;
  padding: 0;
  margin: 0;
}
.editor .filter-list li {
  margin: 10px 0;
}
.editor .filter-list li .filter-item{
  border: 1px solid;
  border-radius: 4px;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  font-weight: normal;
  text-transform: inherit;
  padding: 10px;
  background-color: #e9eaec;
  text-align: left;
}
.editor .filter-list li .filter-item .title{
  font-size: .75rem;
  margin: 0;
  color: #4c4c4c;
}
.editor .filter-list li .filter-item .text{
  font-size: .8125rem;
  margin: 0;
}

.editor .filter-list li .filter-item .delete-icon,
.notes-file-upload ul li .delete-icon,
.editor .group-list .group-item .delete-icon{
  font-size: 18px;
  position: absolute;
  right: 5px;
  opacity: 0.5;
  padding: 5px;
}
.notes-file-upload ul li .delete-icon,
.editor .group-list .group-item .delete-icon{
  cursor: pointer;
  top: 5px;
}
.editor .filter-list li .filter-item .delete-icon:hover,
.notes-file-upload ul li .delete-icon:hover,
.editor .group-list .group-item .delete-icon:hover{
  opacity: 1 !important;
}

.filter-popover-editor{
  padding: 5%;
  width: 410px;
  box-sizing: border-box;
}
.filter-popover-editor .header{
  margin-bottom: 15px;
}
.filter-popover-editor .footer{
  margin-top: 25px;
  text-align: right;
}
.filter-popover-editor .footer button{
  margin-left: 5px;
}

.editor .group-list .group-item{
  border: 1px solid #e9eaec;
  border-radius: 4px;
  background-color: #e9eaec; 
  padding: 10px;
  margin: 5px 0;
  position: relative;
}

.form{
  background: #f4f4f4;
}

.form .header{
  min-height: 5em;
  overflow: hidden;
  position: relative;
}

.form .header .image{
  width: 100%;
  height: 300px;
  background-size: cover !important;
  background-position: center !important;
}

.form .header .title{  
  font-size: 1.5em;
  text-transform: uppercase;
}
.form .header .title-wrapper{  
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.form .header .background{
  opacity: 0.7;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.form .header.step1 .title{  
  padding: 20px 5% 20px;
  margin: 0;
  text-align: left;
  position: relative;
}



.form .content{
  padding: 5%;
  box-sizing: border-box;
}

.form-section{
  width: 100%;
  margin-bottom: 50px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.form-section .section-title{
  text-transform: uppercase;
  text-align: left;
  font-size: 18px;
}

.form-section .amount-box{
  display: flex; 
  border: 1px solid #A7A9AC;
  margin-bottom: 15px;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  min-height: 76px;
}
.form-section .amount-box.active{
  display: flex; 
  border-color: 1px solid #A7A9AC;
  margin-bottom: 10px;
}
.form-section .amount-box .value{
  background-color: black;
  color: white;
  font-size: 20px;
  text-align: center;
  width: 95px;
  border-radius: 3px;
}
.form-section .amount-box .value.fullWidth{
  background-color: white;
  color:black;
}
.form-section .amount-box .value.fullWidth label{
  font-weight: bold;
}
.form-section .amount-box .value input{
  display: none;
}
.form-section .amount-box .value label,
.form-section .amount-box .description{
  display: block;
}
.form-section .amount-box .description .text,
.form-section .amount-box .value label{
  padding: 0 30px;
  top: 50%;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.form-section .signature-wrapper{
  position: relative;
  margin: 30px 0;
  overflow: hidden;
  width: 100%;
}
.form-section .signature-wrapper .jSignature{
  background-color: #e4e4e4 !important;
  border-radius: 4px;
}
.form-section .signature-wrapper #signature{
  display: inline-block;
  width: 100%;
}

.form-section .signature-wrapper .delete-button,
.form-section .signature-wrapper .lock-button{
  position: absolute;
  top: 10px;
  background-color: #000;
  color: white;
  border: none;
  padding: 5px 10px;
  z-index: 99;
}
.form-section .signature-wrapper .delete-button{
  left: 10px;
  border-radius: 2px;
  font-size: 12px;
}
.form-section .signature-wrapper .lock-button{
  right: 10px;
  border-radius: 4px;
}
.form-section .signature-wrapper .lock-button svg{
  font-size: 20px;
}

.form-section.mobile > div{
  width: 100% !important;
}

.accordion-menu{
  display: inline-block;
  width: 200px;
  text-align: left;
  padding: 10px 0 0;
  box-sizing: border-box;
}
.accordion-menu .return{
  cursor: pointer;
}
.accordion-menu .return .icon,
.accordion-menu .return span{
  vertical-align: middle;
}
.accordion-menu .return .icon{
  margin-right: 10px;
  margin-left: 14px;
}
.accordion-menu hr{
  width: 15%;
  margin: 0;
  border: none;
  border-bottom: 1px solid #e4e4e4;
  margin-left: 14px;
}
.accordion-menu ul{
  list-style-type: none;
  padding: 0;
}

.accordion-menu .section{
  margin: 10px 0;
  padding-left: 20px;
}
.accordion-menu .section.opened{
  background-color: #f5f5f5;
  transition: background-color 0.15s ease-out;
} 
.accordion-menu .section:hover{
  cursor: pointer;
}
.accordion-menu .section .icon{
  float: right;
  color: #777770;
}
.accordion-menu .section button{
  padding: 7px 14px;
  border: 0;
  background: 0;
  font-size: 16px;
  color: #777770;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.accordion-menu .section button:hover,
.accordion-menu .section button:hover > .icon{
  color: inherit;
}
.accordion-menu .section button .text{
  vertical-align: middle;
}

.accordion-menu .section.opened button{
  color: black;
  font-weight: 500;
}

.accordion-menu .section.opened button .icon{
  color: black;
}

.accordion-menu .section .section-menu{
  width: 100%;
  max-height: 0;
  overflow: hidden;
  font-size: 14px;
  transition: max-height 0.15s ease-out;
}
.accordion-menu .section.opened .section-menu{
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}
.accordion-menu .section .section-menu .item{
  display: block;
  margin: 10px 0 10px 28px;
}
.accordion-menu .section.opened .section-menu .item.active{
  font-weight: 500;
}

.setup-page{
  background-color: #f5f5f5;
}
.setup-page .menu{
  text-align: left;
  padding: 30px 5% 15px;
}
.setup-page .menu button{
  padding:0;
}

.setup-page .title,
.bonus-list .title{
  text-align: left;
  text-transform: none;
}
.setup-page .title .icon{
  font-size: 30px;
  margin-left: 5px;
}
.setup-page .page{
  padding: 5%;
  box-sizing: border-box;
  text-align: left;
}

.setup-page .create-user .input-wrapper,
.user-setup .personal-data .input-wrapper{
  margin-bottom: 20px;
}

.setup-page .create-user .input-wrapper.avatar-wrapper{
  width: 120px;
  height: 120px;
  margin: 0 auto 40px;
}
.setup-page .create-user .avatar{
  background: white;
  margin: auto;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.setup-page .create-user .avatar .default-image{
  font-size: 134px;
  color: #aaaaaa;
}

.user-setup .header{
  padding: 5% 0 0;
}
.user-setup .header .avatar{
  width: 105px;
  height: 105px;
  margin-right: 20px;
  background-color: white;
  cursor: pointer;
}
.user-setup .header .avatar .default-image{
  font-size: 119px;
  color: #aaaaaa;
}

.user-setup .header .content{
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: left;
}

.table-action-buttons button{
  font-size: 12px;
}
.table-listview{
  display: block !important;
  width: 150px !important;
  margin-bottom: 3% !important;
  text-align: left;
}

table .selectable{
  background: white;
  padding: 8px;
  border-radius: 4px;
}

.user-setup .personal-data{
  padding: 5%;
}

.table-selectionBar{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.table-selectionBar .actions{
  margin-left: auto;
}


.notes{
  overflow: hidden;
  position: initial;
}

/* The actual timeline (the vertical ruler) */
.notes .timeline {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
}
.notes .timeline .wrapper{
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 90px);
  -webkit-overflow-scrolling: touch;
}
.notes .timeline .background{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.8;
}

.notes .note{
  text-align: left;
  display: block;
  width: 65%;
  margin-left: 135%;
  height: 100%;
  background: white;
  box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.1);
  padding: 50px;
  box-sizing: border-box;
  transition: margin 300ms;
  position: fixed;
  top: 70px;
  z-index: 1;
}

.notes .note .action-button{
  margin: -12px -12px -12px 12px;
  color: grey;
}
.notes .note .action-button:hover{
  color: black;
}

.notes .note .author{
  display: flex;
  align-items: center;
}
.notes .note .author .avatar{
  width: 45px;
  height: 45px;
  margin-right: 15px; 
}
.notes .note .author .avatar .default-image{
  font-size: 49px;
  color: white;
}

.notes .note .divider{
  margin: 20px 0;
}

.notes .note .relatedRecordLink{
  padding: 0;
  margin-top: 15px;
  text-decoration: underline;
}

.notes .note .answer-wrapper{
  margin-top: 20px;
  background-color: #eeeeee;
  padding: 10px 15px;
  border-radius: 4px;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 3px;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 35px;
  margin-left: -3px;
  z-index: 0;
}

/* Container around content */
.notes .timeline .container {
  padding: 15px 30px;
  position: relative;
  background-color: inherit;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.4s ease 0s;
  border-bottom: 1px solid #eee;
}

/* The circles on the timeline */
.notes .timeline .container:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: white;
  border: 3px solid rgba(0, 0, 0, 0.8);
  top: 18px;
  border-radius: 50%;
  z-index: 2;
  left: 25px;
}



.notes .timeline .container .arrow {
  opacity: 0;
  height: auto;
  position: absolute;
  top: 15px;
  width: auto;
  z-index: 2;
  left: 34.5%;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
  transition: opacity 1s ease 0s;
}

.notes .timeline .container .date{
  font-size: 14px;
}
.notes .timeline .container .content .author,
.notes .timeline .container .content .title,
.notes .timeline .container .date{
  color: inherit;
}
.notes .timeline .container .date,
.notes .timeline .container .content{
  margin-left: 38px;
}
.notes .timeline .title{
  font-weight: normal;
  line-height: 30px;
}
.notes .timeline .status{
  margin-left: 10px;
  border: 1px solid white;
  border-radius: 4px;
  padding: 3px 6px;
}

.notes .note .author-name,
.notes .note .date{
  display: inline-block;
}
.notes .note .author-name{
  line-height: 20px;
}
.notes .note .date{
  float: right;
}

.notes .note .status div > div > div{
  padding: 10px 30px 10px 10px;
}
.notes .note .status.closed fieldset{
  border: 1px solid #00C853;
}
.notes .note .status.closed div > div > div{
  color: #00C853;
}
.notes .note .status.inProgress fieldset{
  border: 1px solid #0091EA;
}
.notes .note .status.inProgress div > div > div{
  color: #0091EA;
}
.notes .note .status.unread fieldset{
  border: 1px solid #D50000;
}
.notes .note .status.unread div > div > div{
  color: #D50000;
}

.notes .action-bar .actions-wrapper{
  width: 35%;
  display: flex;
  justify-content: flex-start;
} 
.notes .action-bar .actions-wrapper .filter{
  width: 40%;
}

.notes .add-button,
.table-action-buttons{
  position: fixed;
  right: 5%;
  bottom: 5%;
  z-index: 2;
}

.ong-setup .logo-wrapper{
  text-align: center;
  margin: 0 auto 40px;
  width: 100px;
  cursor: pointer;
}
.ong-setup .logo-wrapper img{
  max-height: 200px;
  max-width: 100%;
}
.ong-setup .logo-wrapper .default-logo-icon{
  font-size: 60px;
  color: #8d8d8d;
}
.ong-setup .logo-wrapper .default-logo-icon + span {
  display: block;
  color: #525252;
}
.ong-setup .logo-wrapper input{
  display: none;
}
.ong-setup .input-wrapper{
  margin-bottom: 20px;
}

.dashboard .results{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 40px 0;
  padding: 25px 10px;
}
.dashboard .results .title{
  margin-bottom: 30px;
  margin-left: 15px;
}
.dashboard .results.offline{
  justify-content: space-around;
}

.times{
  text-align: left;
}
.times .header,
.notes .header{
  padding: 3%;
  text-align: left;
}
.times .total{
  margin-left: auto;
}
.times .day-wrapper{
  background-color: white;
  margin: 20px 5%;
}
.times .day{
  padding: 18px 24px 12px;
  position: relative;
  background-color: #eeeeee;
  border: 1px solid #bfbfbf;
  border-bottom: none;
  border-radius: 4px;
}
.times .day::after{
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  background: white;
  z-index: 1;
  left: 0;
  right: 0;
}
.times .person{
  margin: 0 !important;
  border-radius: 0 !important;
}
.times .list{
  display: block;
  padding: 0;
  background-color: #eeeeee;
}
.times .list .list-item{
  border-bottom: 1px solid lightgray;
  display: flex;
  padding: 12px 24px;
  position: relative;
}
.times .list .list-item .options button{
  margin-left: 13px;
  padding: 0;
}


.credits .balance{
  background-color: white;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
}
.credits .balance .balance-title{
  text-transform: uppercase;
  opacity: 0.8;
  font-weight: 300;
}
.credits .balance .value{
  font-weight: bold;
  font-size: 24px;
}
.credits .previous-purchases{
  margin-top: 50px;
}

.goals {
  padding: calc(70px + 3%) 3% 3%;
  text-align: left;
}
.goals .date-selector label, 
.goals .input-with-country label{
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.54);
}
.goals .picker{
  margin: 5px 0;
}

.workdays{
  display: flex;
  text-align: center;
  margin-top: 10px;
}
.workdays .day{
  padding: 10px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-style: solid;
  width: 100%;
  cursor: pointer;
}
.workdays .day input{
  display: none;
}
.workdays .day:nth-child(1){
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 2px;
}
.workdays .day:last-child{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right-width: 2px;
}
.title{
  margin-bottom: 40px;
}
.title .noSecondTitle{
  margin-bottom: 20px;
}
.dates{
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}
.dates > div{
  flex-basis: 48%;
}
.delete-icon{
  font-size: 20px;
  margin-top: 4px;
}

.table-pagination{
	color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
}
.table-pagination .table-pagination-wrapper{
  height: 56px;
  min-height: 56px;
  padding-right: 2px;
  display: flex;
  position: relative;
  align-items: center;
}
.table-pagination .table-pagination-wrapper .spacer{
	flex: 1 1 100%;
}
.table-pagination .table-pagination-wrapper .text{
	flex-shrink: 0;
}
.table-pagination .table-pagination-wrapper .input{
  flex-shrink: 0;
  display: inline-flex;
}
.table-pagination .table-pagination-wrapper .input .select{
  margin-left: 8px;
  margin-right: 32px;
  width: 100%;
  position: relative;
  font-size: inherit;
}
.table-pagination .table-pagination-wrapper .total{
  flex-shrink: 0;
}
.table-pagination .table-pagination-wrapper .actions{
  flex-shrink: 0;
  margin-left: 20px;
}


@media screen and (min-width: 1000px) {
  .gauge-container{
    margin: 0 40px;
  }
}

@media screen and (max-width: 799px) {
  .dashboard .results{
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .notes .action-bar .actions-wrapper{
    width: 100%;
    justify-content: space-evenly;
  }
  .notes .note{
    display: block;
    width: 100%;
  }
  .notes .note{
    margin-left: 101%;
    padding: 35px;
  }
  .notes .note .author-name,
  .notes .note .date{
    display: block;
    float: none;
  }
  .calendar .calendar-wrapper{
    padding: 20px 25px 25px;
  }
}



